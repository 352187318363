<template>
  <div>
    <!-- modal -->
    <b-modal
      id="IndexCreditRefundTableModel"
      :ok-only="false"
      ok-title="ปิด"
      modal-class="modal-primary"
      :title="'ข้อมูล การขอคืนเงิน'"
      size="xl"
      hide-footer
      @ok="clickCancel"
    >
      <h4>
        รายการซื้อรูป
      </h4>
      <TableDataWidget ref="TableDataWidget" :columns="_clientColumn" :items="_clientResult" />
      <hr />
      <h4>
        พันธมิตร คืนเครดิต
      </h4>
      <TableDataWidget ref="TableDataWidget" :columns="_ownColumn" :items="_ownResult" />
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double, email } from '@validations'
export default {
  props: {
    role: String,
  },
  data() {
    return {
      results: '',
    }
  },
  computed: {
    _results() {
      return this.results ?? []
    },

    _clientColumn() {
      return [
        {
          label: 'ข้อความ',
          field: 'image_data_transaction_data.text',
        },
        {
          label: 'รูปซื้อ',
          field: 'image_data_transaction_data.topic_image_data.name',
        },
        {
          label: 'ประเภท',
          field: 'status_text',
        },
        {
          label: 'เครดิตเดิม',
          field: 'credit_balance',
        },
        {
          label: 'เครดิต',
          field: 'credit_amount',
        },
        {
          label: 'เครดิตรวม',
          field: 'credit_total',
        },
      ]
    },
    _clientResult() {
      return this.results?.client ?? []
    },
    _ownColumn() {
      return [
        {
          label: 'อีเมล / เบอร์',
          field: 'user.email',
        },
        {
          label: 'ประเภท',
          field: 'status_text',
        },
        {
          label: 'เครดิตเดิม',
          field: 'credit_balance',
          type: 'number',
        },
        {
          label: 'เครดิต',
          field: 'credit_amount',
          type: 'number',
        },
        {
          label: 'เครดิตรวม',
          field: 'credit_total',
          type: 'number',
        },
      ]
    },

    _ownResult() {
      return this.results?.own ?? []
    },
  },

  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    async show(data) {
      this.results = []
      this.results = await this.api.get(`api/transaction-data/refund/${data.transaction_data_as_id}`)
      this.$bvModal.show('IndexCreditRefundTableModel')
    },

    async clickSave(data) {},
    clickCancel() {},
  },
}
</script>
